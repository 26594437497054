@import "./variables";
@import "~bootstrap/scss/bootstrap";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&display=swap');

body {
  font-family: "Inter", sans-serif !important;
  overflow-x: hidden !important;
}

.deck-common-heading {
  font-size: 3.375rem !important;
  font-weight: 700;
  letter-spacing: -3%;
  line-height: 130%;

  @media (max-width: 576px) {
    font-size: 2.125rem !important;
  }
}

.deck-common-sub-heading {
  font-size: 2.375rem !important;
  font-weight: 600;
  letter-spacing: -2%;
  line-height: 18px;

  @media (max-width: 576px) {
    font-size: 1.355rem !important;
    line-height: auto !important;
  }
}

.deck-common-body-lg {
  font-size: 1.375rem !important;
  font-weight: 400;
  letter-spacing: -0.2%;
  line-height: 150%;

  @media (max-width: 576px) {
    font-size: 1rem !important;
    line-height: 150% !important;
    letter-spacing: 0% !important;
  }
}

.deck-common-body-xl {
  font-size: 1.375rem !important;
  font-weight: 500;
  letter-spacing: -0.2%;
  line-height: 130%;

  @media (max-width: 576px) {
    font-size: 1rem !important;
    line-height: 150% !important;
    letter-spacing: 0% !important;
  }
}

.deck-common-body-md {
  font-size: 1.125rem !important;
  font-weight: 300;
  letter-spacing: -0.2%;
  line-height: 150%;

  @media (max-width: 576px) {
    font-size: 0.875rem !important;
    letter-spacing: 0% !important;
  }
}

.bg-dark {
  background-color: black !important;
}

.fs-50 {
  font-size: 50px;

  @media (max-width: 575.98px) {
    font-size: 30px;
  }
}

.fs-40 {
  font-size: 40px;

  @media (max-width: 575.98px) {
    font-size: 24px;
  }
}

.fs-20 {
  font-size: 20px;

  @media (max-width: 575.98px) {
    font-size: 16px;
  }
}

.fs-26 {
  font-size: 26px;

  @media (max-width: 575.98px) {
    font-size: 16px;
  }
}

.fs-28 {
  font-size: 28px;

  @media (max-width: 575.98px) {
    font-size: 16px;
  }
}

.fs-24 {
  font-size: 24px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.yellow-text {
  color: #ffe500;
  font-weight: 600;
}

.gray-text {
  color: #6b6969;
  font-weight: 500;
}

.bg-peach {
  background-color: #f3e8e7;
}

.rounded-3 {
  border-radius: 1rem !important;
}

.rounded-bottom-3 {
  border-bottom-left-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.text-green {
  color: #00b948;
}

p {
  margin-bottom: 0;
}

.fw-medium {
  font-weight: 500 !important;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.bg-gradient-green {
  background-image: linear-gradient(to right, #baa73a, #d3ca77);
}

.bg-gradient-orange {
  background-image: linear-gradient(to right, #fd942e, #ff6242);
}

.bg-gradient-blue {
  background-image: linear-gradient(to right, #32a7a7, #48bdb2);
}

.bg-gradient-red {
  background-image: linear-gradient(to right, #ec6069, #f08ca4);
}

.bg-green-light {
  background-color: #d6ffd8;
}

.bg-red-light {
  background-color: #FFEFF0;
  color: red;
}

.accordion-item {
  border-width: 0 !important;
  border-bottom: 1px solid #e2e2e2 !important;
}

.rounded-2 {
  border-radius: 0.45rem !important;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-light {
  background-color: #f3f3f3 !important;
}

.accordion-button:not(.collapsed) {
  color: inherit;
  background-color: inherit !important;
  box-shadow: unset;
}

.accordion-button:focus {
  border-color: inherit;
}

.border-dotted {
  border-style: dotted !important;
}

.border-light {
  border-color: #d7d7d7 !important;
}

.badge-outline-dark {
  background-color: white;
  border: 1px solid #000;
  color: black;
}

.btn-success,
.bg-success {
  background-color: #00b948 !important;
  border-color: #00b948 !important;
}

.object-fit-cover {
  object-fit: cover;
}

.no-arrow::after {
  all: unset;
}

.otp-input {
  background-color: #f4f9ff;
  border: 1px solid $primary;
}

.purchase-step {
  @extend .shadow-sm;

  @media (min-width: 580px) {
    border-radius: 1.5rem;
    overflow: hidden;
    box-shadow: none !important;
    border: 1px solid #726d6d4a !important;
    background-color: white;
  }
}

.text-danger {
  color: red;
}

.bg-pink {
  background-color: #F3E8E7;
}

.text-yellow {
  color: #FFE500;
  background: #000;
}

.fw-600 {
  font-weight: 600 !important;
}

.fs-10 {
  font-size: 10px;
}

.text-light {
  color: #575454;
}

.text-bg-outline-dark {
  color: black !important;
  border: solid 0.8px #000
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fw-300 {
  font-weight: 300;
}

.btn-disabled {
  background-color: gray !important;
  opacity: 0.4 !important;
}

// focus-btn-1

.focus-btn-1 {
  padding: 7px 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #00B948;
  background: #DEFFEB;
  border-radius: 55px;
}

.focus-btn-1 p {
  font-size: 18px;
  font-weight: 500;
  color: #212121;
  margin: 0;
}

.focus-btn-1 img {
  width: 24px;
  margin-right: 5px;
}


// focus-btn-2

.focus-btn-2 {
  padding: 8px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #00B948;
  background: #DEFFEB;
  border-radius: 55px;
}

.focus-btn-2 p {
  font-size: 18px;
  font-weight: 400;
  color: #222222;
  margin: 0;
}

.focus-btn-2 img {
  width: 22px;
  margin-right: 7px;
}

// Navbar

.navbar-toggler {
  border: 0;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.navbar .nav-item .nav-link {
  font-size: 17px !important;
  padding: 0 20px;
  color: #323131;
  font-weight: 500;
}

.navbar .nav-item .nav-link:hover {
  color: #1277DF;
}

.navbar-nav {
  display: flex;
  align-items: center;
}

.navbar .imgLink {
  position: relative;
}

.navbar .imgLink img {
  position: absolute;
  top: -18px;
  right: -11px;
}

.navbar .btn-howtoUse {
  background-color: #E8F3FF;
  color: #1477DF !important;
  border-radius: 8px;
  font-size: 17px;
  padding: 11px 22px !important;
  border: 0;
  font-weight: 500;
  margin-right: 20px;
}

.navbar {
  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}

.navbar .outerNav {
  display: none;
}

.navbar .outerNav .btn-howtoUse {
  background-color: #E8F3FF;
  color: #1477DF !important;
  border-radius: 8px;
  font-size: 10px !important;
  font-weight: 500;
  padding: 6px 13px !important;
  text-align: center;
  border: 0;
  margin: 0 !important;
}

.navbar .btn-downloadApp {
  background-color: #1477DF !important;
  color: #FFFF !important;
  border-radius: 8px;
  font-size: 17px;
  padding: 11px !important;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 575.98px) {
    padding: 8px !important;
  }
}

.navbar .btn-downloadApp:hover {
  background-color: #0f64c0;
  color: #FFFF !important;
}

.navbar .nav-link.active {
  color: #1277DF;
}

// hero section
.hero-container {
  padding: 50px 0;
}

.hero-heading {
  font-size: 45px;
  margin-bottom: 20px;
  font-weight: 600;
  color: #212121;
}

.hero-para {
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 400;
  color: #424141;
  font-family: 'Open Sans';
}

.hero-container .img-box {
  display: flex;
  align-items: center;
}

.hero-container .img-box img {
  width: 34px;
  cursor: pointer;
}

.hero-container .img-box a {
  margin-right: 28px;
}

.trusted-by-img {
  width: 234px;
}

.mobile-img {
  display: flex;
  align-items: center;
  justify-content: end;
}

.mobile-img .inner-img-container {
  width: 465px;
}

.mobile-img img {
  width: 100%;
}

.btn-light-blue {
  background-color: #1277DF;
  margin-right: 48px;
  color: #FFFFFF;
  border-radius: 5px;
  padding: 11px 15px;
  border: 0;
  font-size: 17px;
  font-weight: 500;
}

@media (max-width:400px) {
  .btn-light-blue {
    background-color: #1277DF;
    margin-right: 32px;
    color: #fff;
    border-radius: 5px;
    padding: 10px 12px;
    border: 0;
    font-size: 15px;
    font-weight: 400;
  }
}

.btn-light-blue:hover {
  background: #0f64c0;
}

.flex-container {
  display: flex;
  align-items: center;
}

.text-grey {
  color: #424141;
  font-size: 20px;
}

.text-link {
  color: #00B948;
}

// feature-img

.feature-img {
  padding: 30px 0;
}

.feature-img .main-heading {
  font-size: 22px;
  font-weight: 500;
  color: #6B6969;
}

// use opigo

.useOpigo {
  padding: 110px 0;
}

.useOpigo .title-main {
  font-size: 32px;
  font-weight: 600;
  color: #212121 !important;
  margin-bottom: 10px;
}

.useOpigo .para-main {
  font-size: 20px;
  font-weight: 400;
  color: #515969;
  margin-bottom: 55px;
  font-family: 'Open Sans';
}

.useOpigo .card-title {
  font-size: 24px;
  margin-top: 22px;
  margin-bottom: 9px;
  color: #323232;
  font-weight: 600;
}

.useOpigo .card-para {
  font-size: 18px;
  font-weight: 400;
  color: #777575;
  font-family: 'Open Sans';
}

.useOpigo .img-box {
  width: 100px;
  height: 100px;
}

.useOpigo .img-box img {
  width: 100%;
  height: 100%;
}


// Features

.features {
  margin-bottom: 110px;
}

.features .title-main {
  font-size: 32px;
  font-weight: 600;
  color: #212121 !important;
  margin-bottom: 10px;
}

.features .para-main {
  font-size: 20px;
  font-weight: 400;
  color: #7f7f7f;
  font-family: 'Open Sans';
}

.features .inner-col .img-box img {
  width: 100%;
  height: 100%;
}

.features .content-row {
  padding-top: 55px;
}

.features .padding-col {
  padding: 0 20px;
}

.features .flex-container {
  display: flex;
  align-items: center;
}

.features .content-col {
  padding: 18px 14px;
  border: 1px solid transparent;
  border-radius: 20px;
  transition: 0.1s ease;
  cursor: pointer;
}

.features .content-col:hover {
  background-color: var(--feature-card-background-color);
  border: 1px solid var(--feature-card-border-color);
}

.features .content-col .img-box {
  width: 48px;
  height: 48px;
}

.features .content-col .img-box img {
  width: 100%;
  height: 100%;
}

.features .content-col .card-title {
  font-size: 24px;
  font-weight: 600;
  margin-left: 14px;
  color: #000000 !important;
}

.features .content-col .card-para {
  font-size: 18px;
  font-weight: 400;
  font-family: 'Open Sans';
  padding-top: 3px;
  padding-left: 64px;
  color: #807f7f !important;
}

// subscription

.subscription {
  margin-bottom: 110px;
}

.subscription .img-box {
  background-image: linear-gradient(to bottom right, #F7F7F5, #D1ECED, #F6EAEB);
  border-radius: 25px;
  position: relative;
  width: 598px;
  height: 401px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 70px;

  @media (max-width: 1440px) {
    width: 495px;
    height: 346px;
    padding: 65px 23px;
  }
}

.subscription .testimonial {
  width: 100%;
}

.subscription .img-container {
  display: flex;
  justify-content: end;
}

.subscription .img-box i {
  position: absolute;
  right: 30px;
  top: 50%;
  font-size: 24px;
}

.subscription .outer-btn {
  display: none;
  margin-top: 35px;
}

.subscription .inner-btn {
  display: block;
}

.subscription .decks-box {
  margin-bottom: 20px;
  width: 121px;
}

.subscription .decks-box img {
  width: 100%;
}

.subscription .title-main {
  font-size: 32px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 20px;
}

.subscription .para-main {
  font-size: 20px;
  font-weight: 400;
  color: #7f7f7f;
  font-family: 'Open Sans';
  margin-bottom: 35px;
}

.subscription .subscription-value {
  margin-right: 84px;
  margin-bottom: 30px;
}

.subscription .subscription-value .title {
  color: #00B948;
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 2px;
}

.subscription .subscription-value .desc {
  color: #424141;
  font-size: 18px;
  font-weight: 400;
}

.subscription i {
  cursor: pointer;
}

// Testimonial

.user-testimonial {
  margin-bottom: 110px;
}

.testimonial .title-main {
  font-size: 32px;
  font-weight: 600;
  color: #212121;
}

.testimonial .para-main {
  font-size: 20px;
  font-weight: 400;
  color: #7f7f7f;
  font-family: 'Open Sans';
  margin-bottom: 55px;
}

.testimonial .card-col {
  width: 320px;
  height: 350px;
  border: 4px solid #F3F3F3;
  position: relative;
  padding: 36px 20px 20px;
  border-radius: 25px;
  background: #FFFF;
  z-index: 999;

  @media (max-width: 470px) {
    width: 290px;
  }
}

.testimonial .main-col {
  position: relative;
  cursor: pointer;
  margin-top: 8px !important;
}

.testimonial .main-col .liner-box {
  width: 320px;
  height: 54px;
  border-radius: 25px;
  position: absolute;
  top: -6px;

  @media (max-width: 470px) {
    width: 290px;
  }
}

.testimonial .main-para {
  font-size: 16px;
  font-weight: 400;
  color: #575454;
  font-family: 'Open Sans';
}

.testimonial .user {
  position: absolute;
  bottom: 0;
}

.testimonial .user .img-box {
  width: 52px;
  height: 52px;
  margin-right: 14px;
}

.testimonial .flex-container {
  margin-bottom: 9px;
}

.testimonial .user .ratting {
  font-size: 16px;
  color: #FD942E;
}

.testimonial .user .ratting i {
  margin-right: 4px;
}

.testimonial .user .img-box img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.testimonial .user-info .name {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin-bottom: 2px;
}

.testimonial .user-info .status {
  font-size: 12px;
  font-weight: 400;
  color: #514F4F;
  margin: 0;
}

// FAQ

.faq .title-main {
  font-size: 32px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 50px;
}

.faq {
  margin-top: 30px;
  margin-bottom: 110px;
}

footer {
  padding: 50px 0;
  background-color: #161616;
}

footer .img-box {
  width: 147px;
}

footer .img-box img {
  cursor: pointer;
  width: 100%;
}

footer .downloadImgContainer {
  margin-top: 20px;
}

footer .socialIcon {
  margin-bottom: 50px;
}


footer .socialIcon a {
  background-color: #FFFF;
  color: #323232;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  height: 40px;
  width: 40px;
  margin-right: 15px;
  cursor: pointer;
  font-size: 20px;
}

footer .footer-link {
  list-style-type: none;
  padding: 0;
}

footer .tagline {
  font-size: 16px;
  font-weight: 200 !important;
}

footer .col-hed {
  margin-bottom: 28px;
  font-size: 18px;
  font-weight: 500;
}

footer .footer-link li {
  margin-bottom: 20px;
}

footer .footer-link a {
  font-size: 14px;
  font-weight: 400;
  color: #D5D5D5 !important;
}

footer .copyright {
  margin-top: 60px;
  font-size: 13px;
  display: flex;
  align-items: end;
  font-weight: 200;
}

footer .outer-copyright {
  display: none;
}

footer .desclaimer {
  font-size: 16px;
  font-weight: 200;
}

@media (max-width:991px) {

  // focus-btn-1

  .focus-btn-1 {
    padding: 9px 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #00B948;
    background: #DEFFEB;
    border-radius: 55px;
  }

  .focus-btn-1 p {
    font-size: 12px;
    font-weight: 600;
    color: #212121;
    margin: 0;
  }

  .focus-btn-1 img {
    width: 20px;
    margin-right: 5px;
  }

  // focus btn 2

  .focus-btn-2 {
    padding: 5px 10px;
    color: #222222;
  }

  .focus-btn-2 p {
    font-size: 14px;
    font-weight: 400;
  }

  .focus-btn-2 img {
    width: 14px;
    margin-right: 4px;
  }

  // Navbar

  .navbar-nav {
    display: block;
  }

  .navbar .nav-item .nav-link {
    font-size: 17px !important;
    padding: 7px 0;
  }

  .navbar .imgLink {
    width: 50px;
  }

  .navbar .imgLink img {
    position: absolute;
    top: -11px;
    right: -50px;
  }

  .navbar .innerNav {
    display: none;
  }

  .navbar .outerNav {
    display: block;
  }

  .navbar {
    // box-shadow: 0 4px 8px 0 #ececec, 0 6px 20px 0 #ececec;
  }

  .navbar .btn-downloadApp {
    background-color: #1477DF !important;
    color: #FFFF !important;
    border-radius: 5px;
    font-size: 17px;
    height: 48px;
    width: 148px !important;
    border: 0;
  }

  .hero-container {
    padding: 26px 0;
  }

  .hero-heading {
    font-size: 30px;
    font-weight: 600;
  }

  .hero-para {
    font-size: 16px;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .hero-container .img-box img {
    width: 30px;
    cursor: pointer;
  }

  .hero-container .img-box a {
    margin-right: 19px;
  }

  .text-grey {
    color: #424141;
    font-size: 16px;
  }

  .trusted-by-img {
    width: 186px;
  }

  .mobile-img {
    margin-top: 40px;
  }

  // feature-img-sm

  .feature-img-sm .main-heading {
    font-size: 18px;
    font-weight: 400;
    color: #6B6969;
    margin-top: 20px;
  }

  //opigo

  .useOpigo {
    padding-top: 55px;
    padding-bottom: 30px;
  }

  .useOpigo .card-title {
    font-size: 20px;
    margin-top: 22px;
    margin-bottom: 10px;
    color: #323232;
    font-weight: 600;
  }

  .useOpigo .title-main {
    font-size: 20px;
    font-weight: 600;
  }

  .useOpigo .para-main {
    font-size: 14px;
    font-weight: 400;
  }

  .useOpigo .main-imgBox {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .useOpigo .img-box {
    width: 60px;
    height: 60px;
  }

  .useOpigo .main-card {
    margin-bottom: 25px;
  }

  .useOpigo .card-title {
    font-size: 18px;
    font-weight: 600;
  }

  .useOpigo .card-para {
    font-size: 14px;
    font-weight: 400;
  }

  // Features

  .features {
    margin-bottom: 55px;
  }

  .features .title-main {
    font-size: 20px;
    font-weight: 600;
  }

  .features .para-main {
    font-size: 14px;
    font-weight: 400;
  }

  .features .padding-col {
    padding: 0 40px;
  }

  .features .flex-container {
    display: block;
  }

  .features .inner-col .img-box {
    margin-bottom: 30px;
  }

  .features .content-col .card-title {
    font-size: 16px;
    font-weight: 600;
    margin-right: 0;
    margin: 5px 0;
  }

  .features .content-col .card-para {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Open Sans';
    padding-top: 0;
    padding-left: 0;
  }

  .features .content-col .img-box {
    width: 34px;
    height: 34px;
  }

  .features .content-row {
    padding-top: 30px;
  }

  // subscription

  .subscription {
    margin-bottom: 55px;
  }

  .subscription .title-main {
    font-size: 20px;
    font-weight: 600;
  }

  .subscription .para-main {
    font-size: 14px;
    font-weight: 400;
  }

  .subscription .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subscription .img-box {
    height: auto;
    width: auto;
    padding: 25px;
  }

  .subscription .decks-box {
    margin-bottom: 10px;
    width: 71px;
  }

  .subscription .para-main {
    font-size: 14px;
    font-weight: 400;
  }

  .subscription .flex-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
  }

  .subscription .subscription-value {
    margin-right: 0;
  }

  .subscription .subscription-value .title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 7px;
  }

  .subscription .subscription-value .desc {
    font-size: 16px;
    font-weight: 400;
  }

  .subscription .img-box {
    padding: 25px;
  }

  .subscription .img-box i {
    position: absolute;
    right: 8px;
    top: 50%;
    font-size: 18px;
  }

  .subscription .img-container {
    display: block;
  }

  .subscription .inner-btn {
    display: none;
  }

  .subscription .outer-btn {
    display: block;
    margin-right: 0;
  }

  // Testimonial

  .user-testimonial {
    margin-bottom: 64px;
  }

  .testimonial .title-main {
    font-size: 20px;
    font-weight: 600;
  }

  .testimonial .para-main {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 34px;
  }

  .testimonial .scroll-div {
    width: 100%;
    overflow-x: scroll;
  }

  .testimonial .user .ratting {
    font-size: 16px;
    color: #FD942E;
  }

  .testimonial .main-para {
    font-size: 14px;
    font-weight: 400;
    color: #575454;
  }

  .testimonial .user-info .name {
    font-size: 14px;
    margin-bottom: 1px;
  }

  // FAQ

  .faq {
    margin-bottom: 55px;
  }

  .faq .title-main {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  // Footer Responsive

  footer {
    padding: 16px 0;
    background-color: #161616;
  }

  footer .socialIcon {
    margin-top: 22px;
    margin-bottom: 33px;
  }

  footer .col-hed {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  footer .footer-link {
    display: flex;
    align-items: center;
    margin-bottom: 27px;
  }

  footer .footer-link li {
    margin-right: 15px;
    font-size: 14px;
    font-weight: 400;
  }

  footer .desclaimer {
    font-size: 10px;
    font-weight: 400;
  }

  footer .inner-copyright {
    display: none;
  }

  footer .outer-copyright {
    display: block;
    font-size: 12px;
    font-weight: 200 !important;
    margin-top: 22px;
  }
}

// To remove default bootstrap shadow
.accordion-button:focus {
  box-shadow: none !important;
}

.remove-border {
  border: 0 !important;
}

.react-multi-carousel-list {
  position: relative;
}

.slider-next-button {
  position: absolute;
  right: -50px;
  top: -200px;
}

.slider-prev-button {
  position: absolute;
  left: -40px;
  top: -200px;
}

.slider-next-button-decks {
  position: absolute;
  right: -70px;
  top: -160px;

  @media (max-width: 1440px) {
    right: -50px;
    top: -160px;
  }
}

.slider-prev-button-decks {
  position: absolute;
  left: 20px;
  top: -160px;

  @media (max-width: 1440px) {
    left: 40px;
    top: -160px;
  }
}

@media (max-width: 1200px) {
  .slider-next-button,
  .slider-prev-button,
  .slider-next-button-decks,
  .slider-prev-button-decks {
    display: none;
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}


/* Big play Button */
.vjs-theme-forest:hover .vjs-big-play-button,
.vjs-theme-forest.vjs-big-play-button:focus {
  background: transparent;
}

.vjs-big-play-button {
  width: 94px !important;
  height: 94px !important;
  background-color: transparent !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-image: url('../assets/images/video-player-icon.png') !important;
  background-size: cover !important;
  border: none !important;
  top: 50% !important;
  left: 50% !important;
  margin-top: -44px !important;
  margin-left: -44px !important;


  @media (max-width: 575.98px) {
    width: 44px !important;
    height: 44px !important;
    transform: translate(50%, 50%) !important;
  }
}

.vjs-big-play-button .vjs-icon-placeholder {
  display: none;
}

.vjs-play-control:focus {
  outline-style: none !important;
}

.vjs-poster img {
  border-radius: 24px !important;
}

.ytp-hide-controls:not(.ytp-mweb-player) .ytp-watermark {
  display: none !important;
}

.ytp-autohide:not(.ytp-mweb-player) .ytp-watermark:not(.ytp-muted-autoplay-watermark) {
  display: none !important;
}
