.deck-body {
    font-family: "Bricolage Grotesque", sans-serif !important;
    font-optical-sizing: auto !important;
    font-style: normal !important;
    font-variation-settings: "wdth" 100 !important;
}

.header-container {
    width: 100%;
    height: 770px;
    padding: 62px 86px;

    .inner-container {
        margin-top: 61px;
    }
    .video-container {
        width: auto;
        border-radius: 24px;
        overflow: hidden;
    }

    .video-js {
        background-color: #000; /* Change background color */
        border-radius: 10px;
    }

    .video-js .vjs-big-play-button {
        background-color: rgba(255, 255, 255, 0.8); /* Custom play button */
    }

    .video-js .vjs-control-bar {
        background-color: rgba(0, 0, 0, 0.7); /* Custom control bar */
    }

    .video-js .vjs-play-progress {
        background-color: #4caf50; /* Custom play progress bar */
    }

    .header-title {
        color: #222222;
        margin-right: 50px;
    }

    .header-desc {
        color: #1E1E1E;
        margin-top: 22px;
        margin-right: 50px;
    }

    .header-button {
        background-color: #525DF9;
        padding: 16px 24px;
        border-radius: 100px;
        border: none;
        color: #fff;
        border: 4px solid #fff;

        margin-top: 22px;
        transition: 0.3s ease;
    }

    .header-button:hover {
        border: 4px solid rgb(215, 217, 247);

    }

    .header-info-container {
        display: flex;
        gap: 40px;
        margin-top: 50px;
    }

    .header-info-container div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .info-title {
        color: #4F4F4F;
    }

    .info-desc {
        color: #000;
        font-size: 1.5rem;
        font-weight: 600;
        margin-top: 3px;
    }

    .mobile-header {
        display: none;
    }
}

.header-container-deck-page {
    width: 100%;
    background-image: url("../assets/images/deck/deck-header.webp");
    height: 850px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 62px 86px;

    .header-title {
        color: #222222;
        max-width: 498px;
    }

    .header-desc {
        color: #1E1E1E;
        max-width: 434px;
        margin-top: 22px;
    }
}
// ================= Featured ===================

.featured {
    padding: 48px 0 67px 0;

    h2 {
        margin-bottom: 40px;
        color: #282828;
    }
}

// ================= Features ===================

.features-section {
    padding: 90px 0 0 0;
    text-align: center;

    h2 {
        color: #000;
    }

    p {
        color: #1E1E1E;
        margin-top: 21px;
    }
}

// ================= How it's works ===================

.works {
    margin: 95px 15px 129px 15px;
    border-left: 1px solid rgba(82, 93, 249, 0.5);
    border-right: 1px solid rgba(82, 93, 249, 0.5);
    border-bottom: 1px solid rgba(82, 93, 249, 0.5);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    background: linear-gradient(180deg, #FFF 0%, #EBEEFF 100%);

    h2 {
        padding-top: 56px;
        padding-bottom: 82px;
    }

    .works-card-container {
        gap: 102px;
        overflow: hidden;

        .works-card-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .image-box {
                background-color: #DEE0FC;
                height: 64px;
                width: 64px;
                border-radius: 14px;
                border: 1px solid #525DF9;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                .element-image-box {
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    height: 62px;
                    width: 62px;
                    border-radius: 14px;
                    border: 1px dashed #525DF9;
                    clip-path: polygon(44% 8%, 100% 0%, 117% 93%, -17% 119%);
                }
            }

            .image-box .custom-svg {
                height: 30px;
                width: 30px;
            }

            .info {
                margin-top: 20px;
                text-align: center;
            }
        }
    }

    .stepper {
        display: flex;
        align-items: center;
        margin: 0 75px;
        padding-top: 30px;
        padding-bottom: 81px;
    }

    .step {
        width: 56px;
        height: 56px;
        border-radius: 50%;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: #DEE0FC;
        border: 1px dashed #525DF9;
        color: #525DF9;

        font-size: 20px;
        font-weight: 500;
    }

    .connector {
        flex-grow: 1;
        height: 2px;
        border-top: 1px dashed #525DF9;
    }

}

.card-container {
    height: 170px;
    min-height: 170px;
    width: 100%;
    padding: 10px;
    background-color: #F5F6FA !important;
    border-radius: 10px;
}

.card-title-works {
    margin-top: 20px;
    color: #323232;
}

.deck-feature-card {
    border-right: 1px solid #DADADA;
    min-height: 235px !important;
    width: 312px !important;
    padding: 20px 40px;
    margin-top: 32px;
    text-transform: capitalize;

    .img-container {
        height: 50px;
        width: 50px;
        border-radius: 100%;
    }

    h2 {
        margin-top: 20px;
        text-align: start;
    }

    p {
        margin-top: 12px;
        text-align: start;
    }
}

// ================= Explore Decks ===================

.explore-decks {
    background-color: #F5F6FA;
    padding: 62px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.date-filter-container {
    display: flex;
    border-radius: 50px;
    background-color: #fff;
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: center;
}

.date-filter-btn {
    padding: 14px 30px;
    border-radius: 50px;
    margin: 5px;
    font-size: 1.25rem;
    font-weight: 400;
    color: #000;
    cursor: pointer;
}

.date-filter-btn-selected {
    background-color: #525DF9;
    color: #FFF !important;
}

.explore-deck-card {
    background-color: #FFF;
    border: 4px solid #9086FF;
    border-radius: 16px;
    padding: 35px 25px;
    position: relative;
    max-width: 1100px;
    min-width: 1100px;
}

.color-flare {
    height: 300px;
    object-fit: contain;
    position: absolute;
    z-index: 999;
    top: 3px;
    left: -8px;
    pointer-events: none;
}

.spacer {
    background-color: #000;
    width: 1px;
    margin-right: 50px;
    height: 100%;
}

.deck-name {
    font-size: 1.8rem;
    color: #000;
    letter-spacing: -1px;
    font-weight: 600;
}

.deck-category {
    color: #656565;
    font-size: .95rem;
    font-weight: 500;
}

.deck-returns {
    border: 1px solid #CECECE;
    border-radius: 8px;
    padding: 8px 12px;
    color: #656565;
    font-size: 0.875rem;
}
.pricing-container {
    background-color: #FF7F50;
    border-radius: 8px;
    padding: 8px 16px;
    color: white;
}

pricing-container-text {
    font-size: 16px;
}

.plan-selection-container {
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
    justify-content: start;
}

.plan-type-box {
    display: flex;
    align-items: center;
    padding: 16px;
    border-radius: 12px;
    background-color: #F2F3F5;
    border: 1px solid #F2F3F5;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.selectedPlan {
    border: 1px solid rgb(69,166,75);
    background-color: #EDFFF4;
}

.radio-circle {
    width: 18px;
    height: 18px;
    border-radius: 18px;
    border: 1px solid #777777;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}

.selected-rb {
    width: 16px;
    height: 16px;
    border-radius: 40px;
    display: flex;
    border: 2.5px solid #00B948;
}

.plan-details {
    flex: 1;
}

.plan-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.plan-title {
    font-size: 14px;
    font-weight: bold;
    color: #333;
}

.plan-description {
    font-size: 12px;
    color: #807E7E;
}

.target-reward-text {
    font-size: 16px !important;
    color: #000;
    font-weight: 600;
}

.target-reward-sub-text {
    font-size: 1rem;
    font-weight: 300;
}

.deck-price {
    font-size: 2.375rem !important;
    color: #000;
    font-weight: 600;
}

.deck-price-details {
    font-size: 1.125rem;
    font-weight: 400;
}

.subscribe-btn {
    background-color: #525DF9;
    padding: 8px 16px;
    color: #FFF;
    border-radius: 50px;
    border: 0;
    font-size: 1rem;
    font-weight: 400;
    margin-top: 18px;
}

.deck-info {
    color: #000;
}

.deck-info ul {
    list-style-type: none;
    padding-left: 0;
    margin-top: 30px;
}

.deck-info li {
    padding-left: 40px;
    background-image: url('../assets/images/icons/tick.webp');
    background-repeat: no-repeat;
    background-size: 24px 24px;
    margin: 21px 0;
    color: #000;
    font-size: 1rem !important;
    line-height: 150% !important;
}

.tick-icon {
    height: 24px;
    width: 24px;
    margin-right: 18px;
}

// ================= Experts ===================
.experts-section {

    margin-top: 89px !important;

    h2 {
        color: #212121;
        margin-bottom: 41px !important;
        line-height: 130%;
    }

    .expert-img-container {
        border: 1px solid #DEDEDE;
        background-color: #FFF;
        height: 104px;
        width: 104px;
        border-radius: 100%;
        position: relative;
    }

    .expert-exp {
        padding: 4px 10px;
        border-radius: 4px;
        background-color: #525DF9;
        color: #FFF;
        font-size: 0.875rem;
        font-weight: 400;
        position: absolute;
        bottom: -15px;
        left: 0;
        right: 0;
    }

    .expert-img {
        height: 100%;
        width: 100%;
        border-radius: 100%;
        object-fit: contain;
    }

    .expert-name {
        color: #000;
        font-weight: 500;
        width: min-content;
    }
}

// ================= Who should subscribe ===================

.subscribe-section {
    margin-top: 70px !important;

    .subscribe-info-container {
        border: 1px solid #E3E3E3;
        border-radius: 20px;
        padding: 20px 40px;
        align-items: center;
        margin: 10px;
    }

    .subscribe-number {
        background-color: #525DF9;
        border-radius: 100%;
        height: 50px;
        width: 50px;
        color: #FFF;
        font-size: 1.375rem !important;
        font-weight: 400;
    }

    .subscribe-text {
        margin-left: 20px;
        color: #212529;
    }
}

.not-convinced-section {
    padding: 70px 90px;
    background-color: #E7E8FF;

    .title {
        color: #212529;
    }

    .desc {
        color: #000000
    }

    .icon-box {
        height: 50px;
        width: 50px;
        border-radius: 25px;
        border: 1px solid #525DF9;
        color: #525DF9;
        cursor: pointer;
    }

    .active {
        background-color: #525DF9;
        color: #FFF;
        margin-left: 15px;
    }

    .arrow {
        font-size: 1.25rem;
    }
}

.offer-section {
    background-color: #3422FF;
    padding: 18px 16px;
    z-index: 999;

    .title-offer {
        font-size: 0.875rem;
        color: #FFF;
        font-weight: 500;
    }

    .desc-offer {
        color: #FFF;
        font-weight: 400;
        font-size: 0.75rem;
        letter-spacing: -0.2px;
    }


    button {
        background-color: #FFF;
        color: #3422FF;
        border: 0;
        padding: 8px 14px;
        font-size: 0.875rem;
        font-weight: 600;
        border-radius: 90px;
    }

}

.footer-container {
    padding: 134px 93px;
    background-color: #EBECFF;
    border-top-left-radius: 60px;
    border-top-right-radius: 60px;
}



@media (max-width: 575.98px) {
    .header-container {
        height: auto;
        padding: 43px 17px 0 17px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        background-image: url("../assets/images/deck/deck-header-mobile.webp");
        background-position: center;
        overflow: hidden;

        .inner-container {
            margin-top: 0;
            padding-bottom: 80px !important;
        }

        .video-container {
            margin-top: 38px;
        }

        .header-title {
            text-align: center;
            margin-top: 31px !important;
            margin-right: 0 !important;
        }

        .header-desc {
            margin-top: 12px;
            padding: 0 14px;
            margin-right: 0 !important;
        }

        .header-button {
            padding: 12px 18px;
            margin-top: 12px;
        }

        .header-info-container {
            margin-top: 24px;
            gap: 12px;
        }

        .info-title {
            color: #5D5D5D;
        }

        .info-desc {
            color: #000;
            font-size: 1.25rem;
            margin-top: 3px;
        }

        .mobile-header {
            display: block !important;
            width: 120%;
            margin-top: -125px;
            z-index: -10;
        }
    }

    .featured {
        padding: 48px 0 31px 0;

        h2 {
            margin-bottom: 28px;
        }
    }

    .features-section {
        padding: 35px 25px;

        h2 {
            margin-top: 20px !important;
        }

        p {
            font-size: 1rem;
            color: #515969;
            margin-top: 11px !important;
        }
    }

    .works {
        width: auto !important;
        margin: 28px 15px !important;
        border-width: 0 !important;

        h2 {
            padding-top: 0;
        }

        .works-card-container {
            flex-direction: column;
            width: 180px;
            gap: 76px;
            padding-bottom: 50px;

            .works-card-box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .image-box {
                    background-color: #DEE0FC;
                    height: 64px;
                    width: 64px;
                    border-radius: 14px;
                    border: 1px solid #525DF9;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                }

                .element-image-box {
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    height: 62px;
                    width: 62px;
                    border-radius: 14px;
                    border: 1px dashed #525DF9;
                    clip-path: polygon(44% 8%, 100% 0%, 117% 93%, -17% 119%);
                }

                .info {
                    margin-top: 20px;
                    text-align: center;
                }
            }
        }

        .stepper {
            margin: 0;
            padding-bottom: 131px;
            flex-direction: column;
            align-items: flex-start;
        }

        .connector {
            width: 2px;
            border-left: 1px dashed #525DF9;
            align-self: center;
        }
    }

    .card-container {
        width: 45% !important;
        padding: 10px;
        height: auto;
        min-height: auto;
        background-color: #F5F6FA;
        border-radius: 10px;

        h2 {
            margin-bottom: 0 !important;
            font-size: 1rem !important;
        }
    }

    .card-title-works {
        margin-top: 20px !important;
        font-size: 1rem !important;
        color: #323232 !important;
    }

    .date-filter-btn {
        padding: 8px 18px;
        border-radius: 50px;
        margin: 5px;
        font-size: 0.688rem;
        font-weight: 400;
        color: #000;
        cursor: pointer;
    }

    .explore-deck-card {
        padding: 20px 10px;
        min-width: auto;
    }

    .deck-name {
        font-size: 1.300rem !important;
    }

    .deck-category {
        font-size: 0.75rem !important;
    }

    .deck-returns {
        font-size: 0.75rem;
        padding: 6px 10px;
    }

    .deck-price {
        margin-top: 27px;
        font-size: 1.875rem !important;
    }

    .plan-type-box {
        flex: 1 1 calc(100% - 20px);
    }

    .target-reward-text {
        margin-top: 27px;
        font-size: 0.55rem !important;
    }

    .target-reward-sub-text{
        font-size: 0.55rem !important;
    }

    .spacer {
        display: none;
    }

    .deck-info,
    .deck-price-details,
    .deck-info-point {
        font-size: 1rem !important;
    }

    .subscribe-btn {
        background-color: #525DF9 !important;
        padding: 8px 18px;
        color: #FFF;
        border-radius: 50px;
        border: 0;
        font-size: 0.75rem !important;
        font-weight: 400;
        margin-top: 12px;
    }

    .deck-feature-card {
        border-right: 0 solid transparent !important;
        border-bottom: 1px solid #DADADA !important;
        padding: 24px 0;
        margin-top: 10px;
    }

    .experts-section {

        margin-top: 36px !important;

        h2 {
            padding: 0 30px;
        }

        .expert-img-container {
            height: 68px;
            width: 68px;
            background-color: #FFF;
        }

        .expert-img {
            border-radius: 100%;
            object-fit: contain;
        }

        .expert-exp {
            padding: 3px 7px;
            font-size: 0.5rem;
            bottom: -8px !important;
        }

        .expert-name {
            font-size: 0.688rem;
            margin-top: 15px !important;
        }
    }

    .subscribe-section {
        margin-top: 52px !important;

        .subscribe-info-container {
            padding: 15px 13px;
            border: 1px solid #7F7F7F;
        }

        .subscribe-number {
            height: 40px;
            width: 40px;
            font-size: 0.75rem;
        }

        .subscribe-text {
            margin-left: 10px;
            color: #212529;
        }
    }

    .not-convinced-section {
        padding: 28px 0;
        margin-top: 28px;

        .desc {
            font-size: 1rem;
            color: #515969;
            margin-top: 11px !important;
        }

        .icon-box {
            height: 40px;
            width: 40px;
        }

        .active {
            margin-left: 8px;
        }

        .arrow {
            font-size: 1.25rem;
        }
    }

    .footer-container {
        background-color: #F5F6FA;
        padding: 38px 15px;
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
    }

}

@media (max-width: 767.98px) {
    .header-container {
        background-image: url("../assets/images/deck/deck-header-mobile.webp");

        .inner-container {
            margin-top: 0;
        }

        .video-container {
            margin-top: 38px;
        }

        .logo-svg {
            height: 34px;
            margin-left: -15px;
        }
    }


    .header-title {
        font-size: 2.813rem;
        letter-spacing: -1px;
        max-width: 500px;
    }

    .header-desc {
        font-size: 1.125rem;
        margin-top: 11px;
        max-width: 400px;
    }

    .featured {
        padding: 48px 0 31px 0;

        h2 {
            margin-bottom: 28px;
        }
    }

    .features-section {
        padding: 35px 25px;

        h2 {
            margin-top: 20px !important;
        }

        p {
            font-size: 1rem;
            color: #515969;
        }
    }

    .works {
        margin: 28px 15px;

        h2 {
            padding-top: 0;
        }

        .works-card-container {
            flex-direction: column;
            width: 180px;
            gap: 76px;
            padding-bottom: 50px;

            .works-card-box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .image-box {
                    background-color: #DEE0FC;
                    height: 64px;
                    width: 64px;
                    border-radius: 14px;
                    border: 1px solid #525DF9;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                }

                .info {
                    margin-top: 20px;
                    text-align: center;
                }
            }
        }

        .stepper {
            margin: 0;
            padding-bottom: 131px;
            flex-direction: column;
            align-items: flex-start;
        }

        .connector {
            width: 2px;
            border-left: 1px dashed #525DF9;
            align-self: center;
        }
    }

    .mobile-header {
        display: none;
    }

    .card-container {
        width: 45% !important;
        padding: 10px;
        height: auto;
        min-height: auto;
        background-color: #F5F6FA;
        border-radius: 10px;

        h2 {
            margin-bottom: 0 !important;
        }
    }

    .deck-feature-card {
        min-height: auto !important;
    }

    .card-title-works {
        margin-top: 20px !important;
        color: #323232 !important;
        font-size: 1rem !important;
    }

    .subscribe-info-container {
        padding: 20px 15px;
    }

    .date-filter-btn {
        padding: 8px 18px;
        border-radius: 50px;
        margin: 5px;
        font-size: 0.688rem;
        font-weight: 400;
        color: #000;
        cursor: pointer;
    }

    .explore-deck-card {
        padding: 20px 0px;
        min-width: auto;
    }

    .deck-category {
        font-size: 0.75rem !important;
    }

    .deck-returns {
        align-items: flex-start;
        font-size: 0.75rem !important;
        padding: 8px 16px;
    }

    .deck-price {
        margin-top: 27px;
        font-size: 1.875rem !important;
    }

    .plan-type-box {
        flex: 1 1 calc(100% - 20px);
    }

    .target-reward-text {
        margin-top: 27px;
        font-size: 10px !important;
        white-space: nowrap;
    }

    .target-reward-sub-text{
        font-size: 10px !important;
    }

    .spacer {
        display: none;
    }

    .deck-info,
    .deck-price-details,
    .deck-info-point {
        font-size: 1rem !important;
    }
}

@media (min-width: 310.98px) and (max-width: 570.98px){
    .deck-returns {
        font-size: 0.75rem;
        padding: 8px 16px;
    }

    .deck-price {
        margin-top: 27px;
        font-size: 1.875rem !important;
    }

    .plan-type-box {
        flex: 1 1 calc(100% - 20px);
    }

    .target-reward-text {
        margin-top: 27px;
        font-size: 11px !important;
    }

    .target-reward-sub-text{
        font-size: 11px !important;
    }
}

@media (min-width: 768px) and (max-width: 990px){
    .deck-returns {
        align-items: flex-start;
        font-size: 0.75rem;
        padding: 6px 10px;
    }

    .deck-price {
        margin-top: 27px;
        font-size: 1.875rem !important;
    }

    .plan-type-box {
        flex: 1 1 calc(100% - 20px);
    }

    .target-reward-text {
        margin-top: 27px;
        font-size: 10px !important;
    }

    .target-reward-sub-text{
        font-size: 10px !important;
    }
}

@media (max-width: 1100px){
    .explore-deck-card {
        min-width: auto !important;
    }
    .plan-type-box {
        flex: 1 1 calc(100% - 20px);
    }
}

// ========== Animations ==========

$animationSpeed: 40s;

@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-400px * 7))}
}

@keyframes scrollExperts {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-800px))}
}


.slide-track {
    animation: scroll $animationSpeed linear infinite;
    width: calc(550px * 8);
}
.slide {
    height: 100px;
    width: 250px;
}

.experts-slider {
    animation: scrollExperts 35s linear infinite;
    width: calc(68px * 18);
}
