$theme-colors: (
  "primary": #1477df,
  "success": #00b948,
  "warning": #ff942d,
  "dark": #000,
);

.btn {
  color: #fff !important;
}

