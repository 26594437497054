.rewards-my-gift-cards {
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  background-color: #f5f5f5;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 16px;
    border-bottom: 1px solid #E0E0E0;

    .back-button {
     height: 24px;
     width: 24px;
     cursor: pointer;
    }

    .card-header {
      font-size: 17px;
      font-weight: 500;
      color: #203060;
      text-align: center;
    }

    .support-button {
      background-color: #F2F2F2;
      display: flex;
      border: none;
      align-items: center;
      padding: 5px 6px;
      border-radius: 29px;
      cursor: pointer;
      flex-direction: row;

      .whatsapp-icon {
        width: 18px;
        height: 18px;
        margin-right: 4px;
      }

      .support-text {
        font-size: 11px;
        font-weight: normal;
        color: #6E6A6A;
      }
    }
  }

  .gift-card-list {
    margin: 20px 16px;
  }

  .my-gift-main-section {
    background: white;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;

    .my-gift-card {
      display: flex;
      padding: 16px 0 10px 16px;

      .image-container {
        width: 51px;
        height: 51px;
        border-radius: 10px;
      }

      .gift-card-header {
        display: flex;
        align-items: center;


        .store-name {
          align-self: flex-start;
          color: #1477DF;
          font-size: 16px;
          font-weight: 500;
        }

        .expiry-date {
          justify-content: start;
          margin-left: 6px;
          background-color: #FFF2F2;
          color: #E30000;
          padding: 2px 8px;
          border-radius: 20px;
          font-size: 12px;
        }
      }

      .gift-card-details {
        margin-top: 6px;

        p {
          font-size: 11px;
          font-weight: normal;
          color: #858282;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .coupon-info {
          justify-content: flex-start;
        }

        .coupon-row {
          display: flex;
          align-items: center;
          margin-top: 10px;
        }

        .highlight {
          color: #2DA94F;
          white-space: nowrap;
          padding-left: 2px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .copy-icon {
          cursor: pointer;
          width: 10px;
          height: 10px;
          padding-left: 2px;
        }
      }
    }
    .purchase-amount-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 11px 9px 16px;

      p {
        font-size: 11px;
        font-weight: normal;
        color: #858282;
      }
    }

    hr {
      border: none;
      border-top: 2px dashed #D7D7D7;
      margin: 0;
    }
    .gift-card-amount {
      font-size: 14px;
      font-weight: 500;
      color: #2DA94F;
    }
  }
  .popup-notification {
    position: fixed;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #1DA0F2;
    color: white;
    padding: 12px 18px;
    border-radius: 8px;
    font-size: 1rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    text-align: center;
    z-index: 1000;
    animation: fadeInOut 3s;
    max-width: 90%;
    word-wrap: break-word;
  }

  @keyframes fadeInOut {
    0%, 80% { opacity: 1; }
    100% { opacity: 0; }
  }
}

// Responsive design
@media (min-width: 768px) {
  .rewards-my-gift-cards {
    width: 28%;
  }
  .popup-notification {
    font-size: 0.9rem;
    padding: 10px 16px;
  }
}

@media (max-width: 480px) {
  .popup-notification {
    font-size: 0.8rem;
    padding: 8px 12px;
  }
}
