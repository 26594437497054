/* General Styles */
.main-giftCard-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wallet-container {
  margin: 20px auto;
  font-family: 'Arial', sans-serif;
  width: 100%;
}

/* Redeem Reward Screen Styles */
.wallet-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 20px 16px;
  border-bottom: 1px solid #C2C2C2;
}

.back-button {
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.giftCard-header {
  font-size: 14px;
  font-weight: 500;
  color: #203060;
}

.my-coupons-btn {
  background-color: #EAF4FF;
  border: none;
  padding: 4px 6px;
  border-radius: 29px;
  cursor: pointer;
  color: #1477DF;
  font-size: 11px;
  font-weight: 500;
}

/* Wallet Balance Section */
.wallet-balance-card {
  border: 1px solid #C2C2C2;
  border-radius: 10px;
  margin: 20px 16px 0 16px;
  padding: 15px;
  //padding: 15px 13px 9px 13px;
}

.wallet-balance-info {
  display: flex;
  align-items: center;
}

.wallet-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.transactions-btn {
  background-color: #1477DF;
  color: white;
  font-size: 12px;
  font-weight: 500;
  border: none;
  padding: 6px 7px;
  border-radius: 5px;
  cursor: pointer;
}

.separator-line {
  border:  #D3D3D3 dashed 1px;
  margin: 16px 0;
}


/* Gift Cards Section */
.redeem-gift-cards {
  margin: 30px 16px 0 16px;
}

.gift-card-text {
  color: #0A0A0A;
  font-size: 16px;
  font-weight: 500;
}

.company-name {
  font-size: 15px;
  font-weight: 500;
  color: #0A0A0A;
  font-family: 'Gilroy', sans-serif;
  display: -webkit-box; /* Required for webkit browsers */
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical; /* Required for line clamping */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Show "..." for overflowed text */
  word-wrap: break-word; /* Break long words if necessary */
}

.discount-text {
  color: #00B948;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  justify-content: center;
}

.discount-text-Off{
  color: #00B948;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  margin-top: 3px;
  margin-left: 2px;
}

.gift-card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  margin-top: 18px;
}

.gift-card {
  cursor: pointer;
  text-align: center;
}

.gift-card img {
  width: 72px;
  height: 72px;
  border-radius: 10px;
}

.gift-card p {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gift-card span {
  color: green;
}

.see-all-link {
  font-size: 12px;
  display: block;
  text-align: right;
  cursor: pointer;
  text-decoration: underline;
  margin: 10px 20px 0 0;
  color: #1B74CE;
}

/* How it works section */
.how-it-works-main {
  display: flex;
  justify-content: space-between;
  margin: 20px 16px 0 16px;
  align-items: center;
}

.how-it-works {
  margin: 15px 16px 0 16px;
  background-color: #F9F9F9;
  border-radius: 10px;
  padding: 20px;
}

.howWorks-text {
  color: #716E6E;
}

.howWorks-title {
  font-size: 14px;
  font-weight: 500;
}

.terms-text {
  font-size: 12px;
  font-weight: normal;
}

.how-it-works-steps {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.step-item {
  display: flex;
  align-items: center;
}

.step-number {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #716E6E;
  font-weight: 500;
  margin-right: 14px;
}

.step-text {
  font-size: 12px;
  color: black;
  font-weight: normal;
}

//product page
.product-main-container {
  padding: 26px 20px 22px 17px;
  display: flex;
  align-items: center;

  img.brand-logo {
    width: 60px;
    height: 60px;
    border-radius: 10px;
  }

  .brand-name {
    color: black;
    font-size: 16px;
    font-weight: 500;
  }

  h2 {
    font-size: 12px;
    font-weight: normal;
    color: #7A7A7A;
    margin-top: 6px;
  }
}

//card values
.card-value-container {
  margin: 0px 16px 0 16px;

  h4 {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 500;
    color: #1B1B1B;
  }

  .card-input-wrapper {
    position: relative;
    display: flex;
    align-items: center;

    .card-input {
      width: 100%;
      padding: 12px 0 12px 15px;
      font-size: 14px;
      font-weight: normal;
      border: 1px solid #C2CDD1;

      border-radius: 8px;
      color: #404246;
      outline: none;

      &::placeholder {
        color: #B5B5B5;
        font-size: 14px;
        font-weight: normal;
      }
    }
  }

  .card-error-message {
    color: #FB423F;
    font-size: 10px;
    margin-top: 8px;
  }
}

// Email section
.email-input-container {
  margin: 14px 16px 0 16px;

  h4 {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 500;
    color: #1B1B1B;
  }

  .input-wrapper {
    position: relative;
    display: flex;
    align-items: center;

    .email-input {
      width: 100%;
      padding: 12px 0 12px 15px;
      font-size: 14px;
      font-weight: normal;
      border: 1px solid #C2CDD1;
      border-radius: 8px;
      color: #404246;
      outline: none;

      &::placeholder {
        color: #B5B5B5;
        font-size: 14px;
        font-weight: normal;
      }
    }

    .icon {
      position: absolute;
      right: 12px;
      font-size: 18px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: white;
      border: 2px solid transparent;
    }
  }

  .error-message {
    color: #FB423F;
    font-size: 10px;
    margin-top: 8px;
  }
}



.balance-section-container {
  margin: 16px 16px 34px 16px;

  .balance-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;
    padding: 0 12px;
    border-radius: 8px;
    margin-bottom: 16px;

    .balance-info {
      display: flex;
      align-items: center;
      padding: 7px 0;

      .wallet-icon {
        width: 30px;
        height: 30px;
        margin-right: 8px;
      }

      .balance-details {
        display: flex;
        flex-direction: column;

        .balance-title {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          font-size: 12px;
          color: #252323;
          font-weight: 400;

          .balance-off-text {
            color: #00b948;
            font-size: 12px;
            font-weight: 400;
          }

          .no-balance-text {
            color: #f39c12;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
    }

    .balance-checkbox {
      width: 20px;
      height: 20px;
      border: 1px solid #9e9a9a;
      cursor: pointer;
    }
  }

  .payment-button {
    width: 100%;
    padding: 12px;
    background-color: #1477df;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 15px;
    font-weight: normal;
    cursor: pointer;
  }
}

.info-section {
  margin: 16px 16px 0 16px;

  .info-item {
    padding-top: 18px;
    padding-bottom: 9px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-top: 1px solid #F3F3F3;
    color: black;

    h6 {
      font-size: 16px;
      color: #323232;
      font-weight: normal;

      &.right-arrow {
        font-size: 1.25rem;
        font-weight: normal;
      }
    }
  }
  // Modal Styles
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: end;
    z-index: 999;
  }

  .modal-content {
    background-color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 9px 32px 32px 32px;
    width: 100%;
    text-align: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  .modal-wrapper {
    background: #C0C0C0;
    margin-bottom: 18px;
    width: 40px;
    height: 4px;
    border-radius: 40px;
    align-self: center;
  }

  .scrollable-content {
    max-height: 60vh;
    overflow-y: auto;
  }

  .scrollable-content h5 {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    margin: 0;
    padding: 10px 0;
    width: 100%;
  }

  .scrollable-content text {
    align-items: center;
    text-align: left;
    font-size: 16px;
    font-weight: normal;
    width: 100%;
    padding: 0 0;
  }

  .scrollable-content p {
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    margin: 0;
    padding: 3px 0;
    width: 100%;
  }



  .modal-content h4 {
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: bold;
    color: black;
  }

  .modal-content p {
    font-size: 14px;
    font-weight: normal;
    text-align: start;
  }

}


/* Responsive Design */
@media (min-width: 768px) {
  .wallet-container {
    width: 28%;
  }
  .modal-content {
    max-width: 380px;
  }
  .see-all-link {
    margin: 10px 26px 0 0;
  }
  .how-it-works-main {
    margin: 20px 20px 0 16px;
  }
  .how-it-works {
    margin: 15px 20px 0 16px;
  }
}
