.container {
  padding: 15px;
  gap: 16px;
  display: grid;
  flex-wrap: wrap;
}

.tabsContainer {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.tabButton {
  background-color: white;
  color: #6b6b6e !important;
  border: 0.7px solid #dde0e4;
  padding: 12px 24px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  transition: all 0.2s ease-in-out;
}

.tabButton:hover {
  background-color: #f5f5f5;
}

.active {
  border: 0.7px solid #1da0f2;
  color: #1da0f2;
  background: #e6f3fc;
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: flex-start;
}

.top-experts-text {
  width: 100%;
  font-weight: 600;
  font-size: 20px;
  padding: 28px 16px;
  margin-left: 47px; 

}
@media (max-width: 768px) {
  .top-experts-text {
    margin-left: 10px; 
    font-size: 18px; 
    margin-left: 5px;
  }
}

.blank-space-container {
  width: 91%;
  height: 86px;
  background-color: #f0f9ff;
  border-radius: 8px;
  margin: 10px auto 20px;
}

@media (max-width: 768px) {
  .container {
    padding: 10px;
    display: flex;
    gap: 12px;
  }

  .tabsContainer {
    display: flex;
    gap: 8px;
    padding: 10px 5px;
  }

  .tabButton {
    font-size: 12px;
    padding: 8px 16px;
    border-radius: 4px;
    flex-shrink: 0;
  }

  .cardsContainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
    justify-content: center;
  }
}


.expert-card {
  width: 412px; 
  min-width: 280px; 
  height: auto; 
  border: 1px solid #dde0e4;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 14px;
}


@media (max-width: 768px) {
  .expert-card {
    width: 95px; 
  }
}

@media (max-width: 480px) {
  .expert-card {
    width: 330px;
  }
}


.expert-image img {
  width: 100%;
  height: 158px;
  object-fit: cover;
}

.expert-details {
  height: 50%;
  padding: 0px 16px;
  display: flex;
  flex-direction: column;
}

.expert-details h2 {
  font-size: 16px;
  margin: 0;
  display: flex;
  align-items: center;
}

.expert-dp {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.verified-icon {
  margin-left: 6px;
  width: 14px;
  height: 14px;
}

.expert-details p {
  font-size: 14px;
  color: #202020;
  margin-top: 10px;
  line-height: 22px;
}

.expert-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  margin-top: auto;
  margin-bottom: 16.5px;
}

.calls {
  background: #f1f3f6;
  padding: 5px 8px;
  border-radius: 4px;
  color: #6b6b6e;
}

.price {
  font-weight: 500;
  color: #202020;
  font-size: 14px;
  text-align: right;
}
