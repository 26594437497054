/* To prevent arrows in number inputs  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.title {
  @media (max-width: 554.98px) {
    font-size: 18px;
  }
}

.description {
  font-size: 20px;
  font-weight: 200;
  color: #424141;
  font-family: 'Open Sans', sans-serif;
  line-height: 150%;
  text-align: center;

  @media (max-width: 554.98px) {
    font-size: 14px;
    padding: 0 20px;
  }
}

.form-container {
  background: linear-gradient(to right, #1477DF, #F75459, #FFFFFF);
  border-radius: 15px;
  width: 50%;
  margin-top: 20px;

  @media (max-width:1200px) {
    width: 80% !important;
  }

  @media (max-width: 744.98px) {
    width: 100% !important;
  }
}

.form {
  height: 100%;
  width: 100%;
  background-color: #fff;
  margin: 4px; // to show border effect
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 7px 40px 25px;
  border: 1px solid #CBC7C7;

  @media (max-width: 575.98px) {
    padding: 7px 20px 25px;
  }
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px !important;
}

.input-container label {
  color: #1A2B3C;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
}

.input-container input, .input-container select {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  background-color: #F8F8F8;
  padding: 12px 10px;
  border-radius: 4px;
  border: 0 transparent;
}
.input-container select {
  outline: none;
  box-shadow: none !important;
  border-color: transparent !important;
}

.input-container input:focus {
  outline: none !important;
}

.input-container input::placeholder {
  color: #9C9C9C;
}

.submit-btn {
  width: 100%;
  margin-top: 30px;
  padding: 12.5px 0;
  border-radius: 8px;
  background-color: #161616;
  color: #fff;
  font-size: 18px;
}

.success-text {
  color: #7F7F7F;
  font-size: 12px;
  font-weight: 400;
  margin-top: 15px;
  text-align: center;
}
