.congratulation-rewards-main {
  display: flex;
  align-items: center;
  justify-content: center;
}

.congratulation-container {
  width: 100%;
  margin: 0;
  padding: 0;
}
.coupon-success-container {
  padding-top: 70px;
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #1477DF;
  color: white;
  text-align: center;
}

.congrats-heading {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
}

.sub-heading {
  font-size: 14px;
  font-weight: normal;
  margin-top: 6px;
}

.coupon-image {
  width: 100px;
  height: 100px;
  border-radius: 22px;
  border: 2px solid white;
}

.coupon-company-name {
  font-size: 24px;
  font-weight: 500;
  margin: 20px 0;
}

.coupon-message {
  font-size: 14px;
  font-weight: normal;
  margin: 0 33px 0 33px;
  text-align: center;
}

.return-home-button {
  background-color: white;
  color: #161414;
  width: 90%;
  margin-bottom: 39px;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

/* Responsive Design */
@media (min-width: 768px) {
  .congratulation-container {
    width: 28%;
  }
}
